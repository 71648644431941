
.pointer{
    cursor: pointer;
}

.p-dropdown.form-filter{
  .p-dropdown-label{
    padding: 0 0 0 4px;
  }
}
